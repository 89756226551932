import React from "react"
import Layout from "../components/layout/Layout"
import SimpleHero from "../components/SimpleHero"
import { Link } from "gatsby"
import Banner from "../components/Banner/Banner"
import Typed from "react-typed"
import About from "../components/Home/About/About"
import { FaAngleDoubleDown } from "react-icons/fa"
import Services from "../components/Home/Services/Services"
import Contact from "../components/Home/Contact/Contact"
import LatestProject from "../components/Home/LatestProject/LatestProject"
import SEO from "../components/SEO"

export default () => (
  <Layout>
    <SEO title='Home' description="Nolan James Freelance Web Developer based in Worcestershire, UK." />
    <SimpleHero>
      <Banner first='Nolan' last='James' special='B.Sc. (Hons.)' info="">
        <div className='typed-text'>
          <span>I am a </span>
          <Typed
            loop
            typeSpeed={70}
            backSpeed={50}
            strings={["Software Developer", "Full-Stack Web Developer", "Designer", "Composer"]}
            backDelay={1000}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
        </div>
        <Link to='/#services' className="btn-white">What can i do for you <FaAngleDoubleDown/></Link>
      </Banner>
    </SimpleHero>
    <About/>
    <Services/>
    <LatestProject/>
    <Contact/>
  </Layout>
)
