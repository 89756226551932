import React from "react"
import AnimatedBg from "./particles/AnimatedBg"

const SimpleHero = ({ children }) => {

  return (
    <header className='defaultHero'>
      <AnimatedBg/>
      {children}
    </header>
  )
}

export default SimpleHero
