import React from "react"
import Portfolio from "../../Portfolio/Portfolio"
import styles from "./latestProject.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../../Title/Title"
import { graphql, useStaticQuery } from "gatsby"

const getLatestProject = graphql`
    query {
        latestProjects :allContentfulPortfolio(filter: {featured:{eq:true}}) {
            edges {
                node {
                    id
                    title
                    slug
                    category {
                        title
                    }
                    image {
                        fluid {
                            ...GatsbyContentfulFluid_tracedSVG
                        }
                    }
                    description {
                        description
                    }
                    url
                    type {
                        title
                    }
                }
            }
        }
    }
`

const LatestProject = () => {
  const response = useStaticQuery(getLatestProject)
  const latestProjects = response.latestProjects.edges
  return (
    <section className={styles.portfolios}>
      <Title title='Latest' subtitle='Projects' fontColour='title'/>
      <div className={styles.center} data-sal="zoom-in"
           data-sal-delay="200"
           data-sal-easing="ease-in">
        {
          latestProjects.map(({ node }) => {
            return (
              <Portfolio key={node.id} project={node}/>
            )
          })
        }
      </div>
      <AniLink fade to='/portfolio' className='btn-primary'>
        All Projects
      </AniLink>
    </section>
  )
}

export default LatestProject
