import React from "react"
import { FaDiceOne, FaDiceTwo, FaDiceThree } from "react-icons/fa"

export default [
  {
    icon: <FaDiceOne/>,
    title: "Tier 1",
    text: [
      {
        name: "Consultation",
      },
      {
        name: "Website Creation",
      },
    ],
    price: "From £250",
  },
  {
    icon: <FaDiceTwo/>,
    title: "Tier 2",
    text: [
      {
        name: "Consultation",
      },
      {
        name: "Website Creation",
      },
      {
        name: "Content Management *"
      },
      {
        name: "Site Analytics"
      }
    ],
    price: "From £350",
  },
  {
    icon: <FaDiceThree/>,
    title: "Tier 3",
    text: [
      {
        name: "Consultation",
      },
      {
        name: "Website Creation",
      },
      {
        name: "Content Management *"
      },
      {
        name: "Site Analytics"
      },
      {
        name: "Search Engine optimization"
      },
      {
        name: "E-commerce"
      },
      {
        name: "Social Media Integration"
      },
    ],
    price: "Request a Quote",
  },
]
