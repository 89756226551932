import React from "react"
import Title from "../../Title/Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "./about.module.css"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const getAboutImage = graphql`
    query {
        aboutImage:file(relativePath: {eq: "about.jpg"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid

                }
            }
        }
        dynamicIcon:file(relativePath: {eq: "about/dynamic.png"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        fastIcon:file(relativePath: {eq: "about/fast.png"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        responsiveIcon:file(relativePath: {eq: "about/responsive.png"}) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`


const About = () => {

  const { aboutImage, dynamicIcon, fastIcon, responsiveIcon } = useStaticQuery(getAboutImage)

  return (
    <section className={styles.about} id="about">
      <Title title="About" subtitle='Me' fontColour='title'/>
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}
               data-sal="slide-right"
               data-sal-delay="200"
               data-sal-easing="ease-in">
            <Image fluid={aboutImage.childImageSharp.fluid} loading="lazy" alt="about"/>
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <div className={styles.aboutIcons}>
            <Image fluid={fastIcon.childImageSharp.fluid} style={{ width: "20%" }} alt="icon"/>
            <Image fluid={responsiveIcon.childImageSharp.fluid} style={{ width: "20%" }} alt=""/>
            <Image fluid={dynamicIcon.childImageSharp.fluid} style={{ width: "20%" }} alt=""/>
          </div>
          <div className={styles.aboutText}>
            <p className={styles.aboutTextPad}>Fast page loads & User friendly design</p>
            <p className={styles.aboutTextPad}>Responsive layouts that work well on any device</p>
            <p className={styles.aboutTextPad}>Static and Dynamic sites </p>
          </div>
          <hr/>
          <div className={styles.aboutMeText}>
            <p>
              After graduating with a Bachelor's Degree (First
              Class Honours) in Software Development, I embarked on a career as a Freelance Web Developer based in
              Worcestershire, England.<br/>
              <br/>
              I look forward to speaking with you on your next project venture, no matter how big or small.
            </p>
          </div>


          <AniLink fade to='/portfolio' className={`btn-primary ${styles.aboutText}`} data-sal="slide-left"
                   data-sal-delay="200"
                   data-sal-easing="ease-in">
            View my work
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default About
