import React from "react"
import styles from "./contact.module.css"
import Title from "../../Title/Title"

const Contact = () => {
  return (
    <section className={styles.contact} id='contact'>
      <Title title='Contact' subtitle='Me' fontColour='titleSecondary'/>
      <div className={styles.center}>
        <div className={styles.textBorder} data-sal="flip-down"
             data-sal-delay="200"
             data-sal-easing="ease-in">
        <form className={styles.form} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <input type="text" name='name' id='name' className={styles.formControl} placeholder='Your Name...' aria-label='name'/>
          </div>
          <div>
            <input type="email" name='email' id='email' className={styles.formControl} placeholder='Your Email...' aria-label='email'/>
          </div>
          <div>
            <textarea name="message" id="message" rows="10" className={styles.formControl} placeholder='Your message' aria-label='message'/>
          </div>
          <div>
            <input type="submit" value='Send' className={styles.submit}/>
          </div>
        </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
