import React from "react"
import Image from "gatsby-image"
import styles from "./portfolio.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FaLaptopCode } from "react-icons/fa"

const Portfolio = ({ project }) => {
  const { title, slug, category, image, url, type } = project

  return (
    <article className={styles.portfolio}>
      <div className={styles.imgContainer}>
        <Image fluid={image.fluid} className={styles.img} loading="lazy" alt='single project'/>
        {
          url ?
            <a href={url} className={styles.link} target='_blank' rel='noopener noreferrer'>
              Go to site
            </a> :
            <AniLink fade className={styles.link} to={`/projects/${slug}`}>More Info</AniLink>
        }

      </div>
      <div className={styles.footer}>
        <h3>{title}</h3>
        <div className={styles.info}>
          <h4 className={styles.category}>
            <FaLaptopCode className={styles.icon}/>
            {category.title}
          </h4>
          <div className={styles.details}>
            <h6>{type.title}</h6>
          </div>
        </div>
      </div>
    </article>
  )
}

// Portfoliosnotused.propTypes = {
//   project: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//   }),
// }

export default Portfolio
