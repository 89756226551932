import React from "react"
import styles from "./services.module.css"
import Title from "../../Title/Title"
import services from "../../../constants/services"
import { Link } from "gatsby"

const Services = () => {

  return (
    <section className={styles.services} id='services'>
      <Title title='Services' fontColour='titleSecondary'/>
      <div className={styles.center}>
        {
          services.map((item, index) => {
            return (
              <article key={index} className={styles.service}>
                <span>{item.icon}</span>
                <div className={styles.textBorder} data-sal="slide-up"
                     data-sal-delay="200"
                     data-sal-easing="ease-in">
                  <h4>{item.title}</h4>
                  {
                    item.text.map((subItem, subIndex) => {
                      return (
                        <p key={subIndex} className={styles.paragraph}>{subItem.name}</p>
                      )
                    })
                  }
                  <h5 className={styles.servicePrice}><Link to='/#contact'>{item.price}</Link></h5>
                </div>
              </article>
            )
          })
        }
      </div>

    </section>
  )
}

export default Services
