import React from "react"
import styles from "./banner.module.css"

const Banner = ({ first, last, special, info, children }) => {

  return (
    <div className={styles.banner}>
      <h1>
        {first}
        <span className={styles.lastName}> {last}</span>
        <span><small> {special}</small></span>
      </h1>
      <p>{info}</p>
      {children}
    </div>
  )
}

export default Banner
